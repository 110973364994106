<template>
  <div class="mb-10 flex flex-col justify-center items-center">
    <HeaderGlobal
      :step="$t('global_step_01_03')"
      :title="$t('global_header_registration_method')"
      :titlemenu1="titlemenu1"
      :titlemenu2="titlemenu2"
      :titlemenu3="titlemenu3"
    />
    <div class="mb-10 mt-10 w-11/12 mb-8 md:w-10/12">
      <div
        class="mb-2 flex flex-col justify-center items-center"
        v-if="!editable"
      >
        <img
          class="mb-2 rounded-full w-24 h-24"
          rel="prefetch"
          :src="
            image
              ? image
              : 'https://www.its.ac.id/international/wp-content/uploads/sites/66/2020/02/blank-profile-picture-973460_1280-1-300x300.jpg'
          "
          alt="profile picture"
        />
        <h5
          class="
            mt-2
            font-proximaMedium
            text-mediumGrey text-xs text-center
            cursor-pointer
          "
          @click="editAccount"
        >
          {{ $t("global_edit_profile_picture") }}
        </h5>
      </div>
      <InputImage
        @blurred="inputImg"
        :title="$t('global_edit_profile_picture')"
        v-else
      />
      <!-- <h1 class="text-xl font-proximaMedium" v-if="!editable">
        {{ $t("account_title") }}
      </h1>
      <h1 class="text-xl font-proximaMedium" v-else>{{$t('edit_account_title')}}</h1> -->
      <AccountForms
        class="mt-8"
        :editable="editable"
        :data="data"
        ref="child"
      />
      <div class="border-t border-lightGrey20 mt-6" v-if="!editable">
        <SubmitButton
          :title="$t('account_edit_button')"
          background="bg-primary"
          @click.native="editAccount"
        />
        <BorderedButton
          :title="$t('account_delete_button')"
          borderColor="border-red"
          textCl="text-red"
          @click.native="showModal = true"
        />
      </div>
      <div
        class="
          border-t border-lightGrey20
          mt-6
          flex flex-col-reverse
          lg:flex-row
          justify-between
        "
        v-else
      >
        <BorderedButton
          class="lg:mr-1"
          :title="$t('global_cancel')"
          borderColor="border-mediumGrey"
          textCl="text-darkGrey"
          @click.native="editAccount"
        />
        <SubmitButton
          class="lg:ml-1"
          :title="$t('account_save_button')"
          background="bg-primary"
          @click.native="editAccount"
        />
      </div>
    </div>
    <Modals :show="showModal" @close="showModal = false">
      <template #header>
        <h3>custom header</h3>
      </template>
    </Modals>
  </div>
</template>

<script>
import AccountForms from "./AccountForms_.vue";
import InputImage from "../../forms/InputImage.vue";
import SubmitButton from "../../buttons/SubmitButton.vue";
import BorderedButton from "../../buttons/BorderedButton.vue";
import Modals from "../../modals/Modals.vue";
import HeaderGlobal from "../../header/HeaderGlobal.vue";

export default {
  props: { data: {} },
  components: {
    AccountForms,
    InputImage,
    SubmitButton,
    BorderedButton,
    Modals,
    HeaderGlobal,
  },
  data() {
    return {
      image: this.data.profileImage,
      editable: false,
      showModal: false,
      titlemenu1: this.$t("account_dropdown_menu_settings"),
      titlemenu2: "Account Info",
      titlemenu3: "",
    };
  },
  methods: {
    inputImg(val) {
      this.image = val;
    },
    editAccount() {
      this.editable = !this.editable;
      this.titlemenu1 = this.$t("account_dropdown_menu_settings");
      this.titlemenu2 = "Account Info";
      if (this.editable) {
        this.titlemenu3 = this.$t("edit_account_title");
      } else {
        this.titlemenu3 = "";
        this.$refs.child.submitForm(this.image);
      }
    },
  },
};
</script>

<style></style>
