import http from "./httpService";
import axios from "axios";
import { apiUrl } from "../config.json";

export function saveVeterinarian(
  provider,
  profileImage,
  data,
  emailAddress,
  phoneNo,
  practiceType,
  news_updates,
  zone_long,
  zone_lat
) {
  const formData = new FormData();
  profileImage ? formData.append("profileImage", profileImage) : null;
  formData.append("provider", provider);
  formData.append("firstName", data.firstName);
  formData.append("lastName", data.lastName);
  formData.append("streetAddress", data.streetAddress);
  formData.append("country", data.country);
  formData.append("state", data.state);
  formData.append("city", data.city);
  formData.append("postalCode", data.postalCode);
  formData.append("emailAddress", emailAddress);
  formData.append("phoneNo", data.phoneNo);
  formData.append("website", data.website);
  formData.append("practiceType", practiceType);
  formData.append("practiceName", data.practiceName);
  formData.append("taxID", data.taxID);
  formData.append("news_updates", news_updates);
  formData.append("zone_long", zone_long);
  formData.append("zone_lat", zone_lat);
  return http.post(apiUrl + "veterinarians/veterinarian/", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export function updateVeterinarian(profileImage, form) {
  const formData = new FormData();
  profileImage ? formData.append("profileImage", profileImage) : null;
  formData.append("firstName", form.firstName);
  formData.append("lastName", form.firstName);
  formData.append("streetAddress", form.address);
  formData.append("country", form.country);
  formData.append("state", form.state);
  formData.append("city", form.city);
  formData.append("postalCode", form.postalCode);
  formData.append("emailAddress", form.emailAddress);
  formData.append("phoneNo", form.phoneNumber);
  formData.append("website", form.website);
  formData.append("practiceType", form.practiceType);
  formData.append("practiceName", form.practiceName);
  formData.append("taxID", form.taxID);
  return http.put(
    apiUrl + "veterinarians/veterinarian/" + form.emailAddress,
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
}
