<template>
  <div>
    <FormError :errors="errorEdit" />
    <div class="flex justify-between">
      <div class="w-full mr-1 flex flex-col justify-start items-start relative">
        <label class="text-sm font-proximaMedium" for="firstName">{{
          $t("global_firstname")
        }}</label>
        <input
          type="text"
          id="firstName"
          placeholder="John"
          v-model="firstName"
          class="w-full rounded-sm px-3 py-3 my-1 text-sm placeholder-darkGrey"
          :class="{ 'focus:outline-none': !editable }"
          :readonly="!editable"
        />
        <FormError :errors="errorFirstName" />
        <img
          class="absolute right-2 top-9"
          rel="prefetch"
          src="@/assets/images/edit-icon.png"
          alt="edit-icon"
          v-if="editable"
        />
      </div>
      <div class="w-full ml-1 flex flex-col justify-start items-start relative">
        <label class="text-sm font-proximaMedium" for="lastName">{{
          $t("global_lastname")
        }}</label>
        <input
          type="text"
          id="lastName"
          placeholder="Doe"
          v-model="lastName"
          class="w-full rounded-sm px-3 py-3 my-1 text-sm placeholder-darkGrey"
          :class="{ 'focus:outline-none': !editable }"
          :readonly="!editable"
        />
        <FormError :errors="errorLastName" />
        <img
          class="absolute right-2 top-9"
          rel="prefetch"
          src="@/assets/images/edit-icon.png"
          alt="edit-icon"
          v-if="editable"
        />
      </div>
    </div>
    <div class="mt-5 flex flex-col justify-start items-start relative">
      <label class="text-sm font-proximaMedium" for="email"
        >{{ $t("global_email") }} *</label
      >
      <input
        type="text"
        id="email"
        placeholder="email@email.com"
        v-model="emailAddress"
        class="w-full rounded-sm px-3 py-3 my-1 text-sm placeholder-darkGrey"
        :class="{ 'focus:outline-none': !editable }"
        :readonly="!editable"
      />
      <img
        class="absolute right-2 top-9"
        rel="prefetch"
        src="@/assets/images/edit-icon.png"
        alt="edit-icon"
        v-if="editable"
      />
    </div>
    <div class="mt-5 flex flex-col justify-start items-start relative">
      <label class="text-sm font-proximaMedium" for="phoneNumber"
        >{{ $t("global_phone_number") }} *</label
      >
      <vue-tel-input
        id="phoneNumber"
        required
        :inputOptions="options"
        validCharactersOnly
        v-model="phoneNumber"
        class="w-full"
        @update="onUpdate"
        :readonly="!editable"
      />
    </div>
    <div class="mt-5 flex flex-col justify-start items-start relative">
      <label class="text-sm font-proximaMedium" for="state">{{
        $t("service_veterinarian_clinic_type")
      }}</label>
      <InputSelect
        selectedVal="this.practiceType"
        id="state"
        :dataList="dataList"
        class="w-full"
        :editable="!editable"
        @blurred="inputSelectState"
      />
      <FormError :errors="errorPracticeType" />
    </div>

    <div class="mt-5 flex flex-col justify-start items-start relative">
      <label class="text-sm font-proximaMedium">{{
        $t("account_clinic_name")
      }}</label>
      <input
        type="text"
        placeholder="Pet Home"
        v-model="practiceName"
        class="w-full rounded-sm px-3 py-3 my-1 text-sm placeholder-darkGrey"
        :class="{ 'focus:outline-none': !editable }"
        :readonly="!editable"
      />
      <FormError :errors="errorPracticeName" />
      <img
        class="absolute right-2 top-9"
        rel="prefetch"
        src="@/assets/images/edit-icon.png"
        alt="edit-icon"
        v-if="editable"
      />
    </div>
    <div class="mt-5 flex flex-col justify-start items-start relative">
      <label class="text-sm font-proximaMedium">{{
        $t("account_business_website")
      }}</label>
      <input
        type="text"
        placeholder="https://www.perpetual.com"
        v-model="website"
        class="w-full rounded-sm px-3 py-3 my-1 text-sm placeholder-darkGrey"
        :class="{ 'focus:outline-none': !editable }"
        :readonly="!editable"
      />
      <img
        class="absolute right-2 top-9"
        rel="prefetch"
        src="@/assets/images/edit-icon.png"
        alt="edit-icon"
        v-if="editable"
      />
    </div>
    <div class="mt-5 flex flex-col justify-start items-start relative">
      <label class="text-sm font-proximaMedium">{{
        $t("account_business_tax_id") + "*"
      }}</label>
      <input
        type="text"
        placeholder="958938488"
        v-model="taxID"
        class="w-full rounded-sm px-3 py-3 my-1 text-sm placeholder-darkGrey"
        :class="{ 'focus:outline-none': !editable }"
        :readonly="!editable"
      />
      <img
        class="absolute right-2 top-9"
        rel="prefetch"
        src="@/assets/images/edit-icon.png"
        alt="edit-icon"
        v-if="editable"
      />
    </div>
    <div class="mt-5 flex flex-col justify-start items-start relative">
      <label class="text-sm font-proximaMedium" for="streetAddress">{{
        $t("global_street_address")
      }}</label>
      <input
        type="text"
        id="streetAddress"
        placeholder="address"
        v-model="address"
        class="w-full rounded-sm px-3 py-3 my-1 text-sm placeholder-darkGrey"
        :class="{ 'focus:outline-none': !editable }"
        :readonly="!editable"
      />
      <FormError :errors="errorAddress" />
      <img
        class="absolute right-2 top-9"
        rel="prefetch"
        src="@/assets/images/edit-icon.png"
        alt="edit-icon"
        v-if="editable"
      />
    </div>
    <div class="mt-5 flex justify-between">
      <div class="w-full mr-1 flex flex-col justify-start items-start">
        <label class="text-sm font-proximaMedium" for="country">{{
          $t("global_country")
        }}</label>
        <InputSelect
          selectedVal="this.country"
          id="country"
          :dataList="countriesList"
          class="w-full"
          :editable="!editable"
          @blurred="inputSelectCountry"
        />
        <FormError :errors="errorCountry" />
      </div>
      <div class="w-full ml-1 flex flex-col justify-start items-start">
        <label class="text-sm font-proximaMedium" for="state">{{
          $t("global_state")
        }}</label>
        <InputSelect
          selectedVal="this.state"
          id="state"
          class="w-full"
          :dataList="statesList"
          :editable="!editable"
          @blurred="inputSelect"
        />
      </div>
    </div>
    <div class="mt-5 flex justify-between">
      <div class="w-full mr-1 flex flex-col justify-start items-start">
        <label class="text-sm font-proximaMedium" for="city">{{
          $t("global_city")
        }}</label>
        <input
          type="text"
          id="city"
          placeholder="City"
          v-model="city"
          class="w-full rounded-sm px-3 py-3 my-1 text-sm"
        />
        <FormError :errors="errorCity" />
      </div>
      <div class="w-full ml-1 flex flex-col justify-start items-start relative">
        <label class="text-sm font-proximaMedium" for="postalCode">{{
          $t("global_postal_code")
        }}</label>
        <input
          type="text"
          id="postalCode"
          placeholder="123456"
          v-model="postalCode"
          class="w-full rounded-sm px-3 py-3 my-1 text-sm placeholder-darkGrey"
          :class="{ 'focus:outline-none': !editable }"
          :readonly="!editable"
        />
        <FormError :errors="errorPostalCode" />
        <img
          class="absolute right-2 top-9"
          rel="prefetch"
          src="@/assets/images/edit-icon.png"
          alt="edit-icon"
          v-if="editable"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InputSelect from "../../forms/InputSelect.vue";
import InputRadio from "../../forms/InputRadio.vue";
import "vue-tel-input/dist/vue-tel-input.css";
import { VueTelInput } from "vue-tel-input";

import { updateVeterinarian } from "../../../services/veterinarianService";
import { getStates } from "../../../services/stateServices";
import { getCountries } from "../../../services/countriesServices";

export default {
  props: ["editable"],
  components: {
    InputSelect,
    InputRadio,
    VueTelInput,
  },
  data() {
    return {
      dataList: [
        { id: 1, name: "General practice" },
        { id: 2, name: "Mobile Practice" },
        { id: 3, name: "Species-specific clinic (e.g. avian)" },
        { id: 4, name: "Specialist clinic (e.g. cardiologist)" },
        { id: 5, name: "Emergency service" },
        { id: 6, name: "Hospital" },
      ],
      form: {
        firstName: "",
        lastName: "",
        emailAddress: "",
        address: "",
        country: "",
        state: "",
        city: "",
        postalCode: "",
        website: "",
        practiceName: "",
        practiceType: "",
        taxID: "",
        phoneNumber: "",
      },
      countriesList: [],
      statesList: [],
      errorFirstName: [],
      errorLastName: [],
      errorAddress: [],
      errorCountry: [],
      errorState: [],
      errorCity: [],
      errorPostalCode: [],
      errorPracticeName: [],
      errorPracticeType: [],
      errorTaxID: [],
      errorEdit: [],
      options: {
        placeholder: this.$t("global_phone_number_placeholder"),
      },
    };
  },
  async created() {
    const a = await getCountries();
    this.countriesList = a.data;

    if (this.country) {
      const c = await getStates(this.country);
      this.statesList = c.data;
    }
  },
  methods: {
    onUpdate(payload) {
      this.phoneNumber = payload.formattedNumber;
    },

    async inputSelectState(val) {
      this.state = val;
    },

    inputSelect(val) {
      this.practiceType = val;
    },

    async submitForm(image) {
      try {
        await updateVeterinarian(image, form);
      } catch (error) {
        this.errorEdit.push("Edit error.");
      }
    },
  },
};
</script>

<style>
.vti__dropdown {
  border: 1px solid #ccc;
  border-radius: 0.125rem 0 0 0.125rem;
}
.vue-tel-input {
  border-radius: 0.125rem;
  border: none;
  font-size: 0.875rem;
}
.vti__input {
  padding: 0.75rem 10px 0.75rem;
  border-radius: 0 0.125rem 0.125rem 0;
}
.vue-tel-input:focus-within {
  box-shadow: none;
  border-color: none;
  outline: auto;
}
</style>
